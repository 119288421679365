import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

export const Gifts = () => {
  return (
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Card sx={{ maxWidth: 545, mt: 3, mb: -2 }}>
        <CardMedia
          component="img"
          height="100"
          image="https://res.cloudinary.com/dsw2tubha/image/upload/v1663959986/RodriWedding/Cheers_cgnv2q.jpg"
          alt="Regalo"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <CardGiftcardIcon /> Regalos <CardGiftcardIcon />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Se agradecen sus muestras de afecto en regalo de sobre
          </Typography>
          <Typography variant="caption" color="text.secondary"></Typography>
        </CardContent>
        <CardActions>
          <Button href=" https://wa.link/lw1l29" color="secondary">
            Mas informacion aqui <WhatsAppIcon />
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
