import "./App.css";
import { Routes, Route } from "react-router-dom";
import Invitation from "./Pages/Invitation/invitation";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/invitacion" element={<Home />}></Route>
        <Route path="/invitacion/:inviteId" element={<Invitation />}></Route>
      </Routes>
    </div>
  );
}

export default App;
