import Locations from "./components/Locations/Locations";
import InviteTitle from "./components/InviteTitle/InviteTitle";
import { useParams } from "react-router-dom";
import urlInviteToInviteName from "../../utils/urlInviteToInviteName";
import InvitationDataService from "../../../src/services/invitation.service";
import ImageGallery from "./components/ImageGallery/ImageGallery";
import { Suspense, useEffect, useState } from "react";
import DinnerPlate from "./components/DinnerPlate/DinnerPlate";
import { Backdrop, CircularProgress, Grid, LinearProgress } from "@mui/material";
import React from "react";
import { Gifts } from "./components/Gifts/Gifts";

const backgroundImage = "https://res.cloudinary.com/dsw2tubha/image/upload/v1665539533/RodriWedding/20220605_171959_pim4p5.jpg";
const InviteConfirmation = React.lazy(() => import("./components/InviteConfirmation/InviteConfirmation"));

export default function Invitation() {
  const [invitationName, setInvitationName] = useState("");
  const [invitationPlus, setInvitationPlus] = useState(0);
  const [invitationTable, setInvitationTable] = useState("");
  const [invitationStatus, setInvitationStatus] = useState("");
  const [isAnswered, setIsAnswered] = useState(0);
  const [invitationId, setInvitationId] = useState("");
  const [Loading, setLoading] = useState(true);

  const params = useParams();
  let inviteId = params.inviteId !== undefined ? params.inviteId : "";

  let inviteName = "";
  if (params.inviteId !== undefined) {
    inviteName = urlInviteToInviteName(inviteId);
  }

  const getInvitation = async () => {
    InvitationDataService.findBySlug(inviteId)
      .then((data) => {
        console.log(data);
        if (data[0] !== undefined) {
          let name = data[0].get("Name") !== undefined ? data[0].get("Name")?.valueOf().toString() : "";
          let plus = data[0].get("AddedPlusInvitations") !== undefined ? data[0].get("AddedPlusInvitations")?.valueOf() : 0;
          let table = data[0].get("Table") !== undefined ? data[0].get("Table")?.valueOf().toString() : "";
          let status = data[0].get("Status") !== undefined ? data[0].get("Status")?.valueOf().toString() : "";
          let isAnswered = data[0].get("IsAnswered") !== undefined ? data[0].get("IsAnswered")?.valueOf() : 0;

          setInvitationName(name !== undefined ? name : "");
          setInvitationPlus(plus !== undefined ? Number(plus) : 0);
          setInvitationTable(table !== undefined ? table : "");
          setInvitationStatus(status !== undefined ? status : "");
          setIsAnswered(isAnswered !== undefined ? Number(isAnswered) : 0);
          setInvitationId(data[0].getId());

          setLoading(false);
        }
      })
      .catch((error) => console.log("Authorization Failed : " + error.message));
  };

  const updateInvitationStatus = async (confirmation: string, inviteId: string) => {
    InvitationDataService.updateStatus(confirmation, inviteId);
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    getInvitation();
  });

  return (
    <>
      <h3>Esta es tu invitacion a nuestra boda {inviteName}</h3>

      <InviteTitle name={invitationName} main_image={backgroundImage}></InviteTitle>
      <Locations></Locations>
      <DinnerPlate></DinnerPlate>
      <Gifts />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Loading}
        onClick={() => {
          setLoading(false);
        }}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      <Suspense fallback={<LinearProgress sx={{ mt: 2, mb: 2 }} />}>
        <InviteConfirmation
          name={invitationName}
          table={invitationTable}
          plus={invitationPlus}
          invitationStatus={invitationStatus}
          isAnswered={isAnswered}
          inviteId={invitationId}
          updateInvitationStatus={updateInvitationStatus}
        ></InviteConfirmation>
      </Suspense>
      <ImageGallery></ImageGallery>
    </>
  );
}
