/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import InviteTitle from "../Invitation/components/InviteTitle/InviteTitle";

const backgroundImage =
  "https://res.cloudinary.com/dsw2tubha/image/upload/q_92/v1667670961/RodriWedding/FotosCompromiso/Lagocompromiso.jpg";

export const Home = () => {
  return (
    <>
      <InviteTitle name="" main_image={backgroundImage}></InviteTitle>
      <Grid container display="flex" justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} sx={{ mr: 2, ml: 2 }}>
          <Card sx={{ mt: 3, mb: 3 }}>
            <CardHeader title="Lista de Invitados" subheader="Encuentra tu invitacion" />
            <CardContent>
              <iframe
                className="airtable-embed"
                src="https://airtable.com/embed/shrqgF0LRvSIu1Jyy?backgroundColor=blue&viewControls=on"
                frameBorder="0"
                width="100%"
                height="533"
              ></iframe>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default Home;
