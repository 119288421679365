
import Airtable, { FieldSet, Table } from "airtable";
import { AirtableBase } from "airtable/lib/airtable_base";

class InvitationDataService {
  base: AirtableBase
  invitationTable: Table<FieldSet>
  constructor() {
    this.base = new Airtable({apiKey: 'keyOZDAXhzU60PmV2'}).base('apppiatu9hYC93yKS');
    this.invitationTable = this.base("Invitations");
  }

  findBySlug(slug: string) {
    return this.invitationTable.select({
      filterByFormula: `slug = "${slug}"`
   }).firstPage()
   }

   updateStatus(status: string, id: string) {
    return this.invitationTable.update([
      {
        "id": id,
        "fields": {
          "Status": status,
        }
      
      }
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      
    });
   }

 

 
}

export default new InvitationDataService();
