import { Box, Modal, Paper, Typography } from "@mui/material";
import Image from "mui-image";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  boxShadow: 24,
};

export const Lightbox = (props: any) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        onClick={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Image
            src={props.selectedImage}
            height="100%"
            width="100%"
            fit="cover"
            duration={1000}
            showLoading={false}
            errorIcon={true}
            distance="100px"
            shiftDuration={300}
            bgColor="inherit"
          />
        </Box>
      </Modal>
    </div>
  );
};
