import Typography from "@mui/material/Typography";
import InviteTitleLayout from "./InviteTitleLayout";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import Button from "@mui/material/Button";

type invitation = {
  name: string;
  main_image: string;
};
export default function InviteTitle(invitation: invitation) {
  return (
    <InviteTitleLayout
      sxBackground={{
        backgroundImage: `url(${invitation.main_image})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: "none" }} src={invitation.main_image} alt="increase priority" />

      <Typography variant="h1">Rodri & Tati</Typography>
      <Typography color="inherit" align="left" variant="subtitle1" sx={{ mb: 3, mt: { sx: 3, sm: 5 } }}>
        Hay momentos en la vida que son muy especiales pero al compartirlos con personas como tu se vuelven inolvidables
      </Typography>

      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="https://calendar.google.com/event?action=TEMPLATE&tmeid=NjZ0Y2gyamhhazE0bWEyNW9mZnJkNmpjczQgcm9iZXJ0b2Zsb3JlczI3OTBAbQ&tmsrc=robertoflores2790%40gmail.com"
        sx={{ minWidth: 200 }}
        endIcon={<EventAvailableIcon />}
      >
        Nuestra boda: 10 de diciembre, guardalo en tu calendario!
      </Button>
      <Typography color="inherit" align="left" variant="body1" sx={{ mb: 1, mt: { sx: 2, sm: 3 } }}>
        Y es por eso que nos encantaría {invitation.name} contar con tu presencia
      </Typography>
    </InviteTitleLayout>
  );
}
