import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export default function ReceptionLocation() {
  return (
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Grid xs display="flex" justifyContent="center" alignItems="center">
        <Card sx={{ mt: 2, mb: 3, ml: -2 }}>
          <CardActionArea
            href="https://g.page/BarceloSanSalvador?share"
            target="_blank"
          >
            <CardMedia
              component="img"
              height="50"
              width="50"
              image="https://res.cloudinary.com/dsw2tubha/image/upload/c_crop,h_400,w_640/v1664049432/RodriWedding/SalonDeBoda_wtxlfb.png"
              alt="ReceptionLocation"
            />
            <CardContent>
              <Typography gutterBottom variant="h3" component="div">
                Recepcion
              </Typography>
              <Typography variant="h4" color="text.secondary">
                Hotel Barcelo San Salvador
              </Typography>
              <Typography variant="h6" color="text.secondary">
                Terraza - Hora 6:00 PM
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              size="small"
              color="secondary"
              href="https://g.page/BarceloSanSalvador?share"
            >
              Ir al mapa
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
