import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import { useState } from "react";
import { Lightbox } from "../../../../components/Lightbox";

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}
const imageSX = {
  opacity: 0.7,
  "&:hover": {
    opacity: 1,
  },
};

export default function ImageGallery() {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Paper elevation={2} sx={{ mb: 3 }}>
        <ImageList variant="quilted" cols={4} rowHeight={120} sx={{ mr: 1, ml: 1, mt: 1, mb: 1 }}>
          {itemData.map((item) => (
            <ImageListItem
              sx={imageSX}
              onClick={() => {
                setOpen(true);
                setSelectedImage(item.img);
              }}
              key={item.img}
              cols={item.cols || 1}
              rows={item.rows || 1}
              //sx={{
              //           opacity: ".7",
              //          transition: "opacity .3s linear",
              //         cursor: "pointer",
              //        "&:hover": { opacity: 1 },
              //      }}
            >
              <img {...srcset(item.img, 121, item.rows, item.cols)} alt={item.title} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </Paper>
      <Lightbox open={open} handleOpen={handleOpen} handleClose={handleClose} selectedImage={selectedImage} />
    </Grid>
  );
}

const itemData = [
  {
    img: "https://res.cloudinary.com/dsw2tubha/image/upload/e_auto_color,q_100/v1667794132/RodriWedding/FotosCompromiso/anillolago2.jpg",
    title: "Anillo",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://res.cloudinary.com/dsw2tubha/image/upload/e_auto_brightness,q_100/v1667670961/RodriWedding/FotosCompromiso/Lagocompromiso.png",
    title: "LagoCompromiso",
  },
  {
    img: "https://res.cloudinary.com/dsw2tubha/image/upload/e_auto_brightness,q_100/v1667767128/RodriWedding/1erdetalledeRodry/1erdetalleRodry.png",
    title: "detalle",
  },
  {
    img: "https://res.cloudinary.com/dsw2tubha/image/upload/e_auto_brightness,q_100/v1667768922/RodriWedding/FotosCompromiso/Confirmaci%C3%B3ndecompromiso.png",
    title: "Compromiso",
    cols: 2,
  },
  {
    img: "https://res.cloudinary.com/dsw2tubha/image/upload/e_auto_brightness/v1667769309/RodriWedding/Lagojuntos/Lagojuntos.jpg",
    title: "cumple",
    cols: 2,
  },
  {
    img: "https://res.cloudinary.com/dsw2tubha/image/upload/e_auto_brightness,q_100/v1667767887/RodriWedding/Aventuras/Aventuras.png",
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
  },
  {
    img: "https://res.cloudinary.com/dsw2tubha/image/upload/e_auto_brightness,q_100/v1667770260/RodriWedding/Playajuntos/Playajuntos3.png",
    title: "Basketball",
  },
  {
    img: "https://res.cloudinary.com/dsw2tubha/image/upload/e_auto_brightness,q_100/v1667770266/RodriWedding/Playajuntos/Playajuntos2.png",
    title: "Fern",
  },
];
