import Grid from "@mui/material/Unstable_Grid2";
import CeremonyLocation from "../CeremonyLocation/CeremonyLocation";
import ReceptionLocation from "../ReceptionLocation/ReceptionLocation";

export default function Locations() {
  return (
    <Grid
      display="flex"
      container
      columnSpacing={{ xs: 2, sm: 2, md: 2 }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        display="flex"
        xs={12}
        md={6}
        sm={6}
        justifyContent="center"
        alignItems="center"
      >
        <CeremonyLocation></CeremonyLocation>
      </Grid>
      <Grid
        display="flex"
        xs={12}
        md={6}
        sm={6}
        justifyContent="center"
        alignItems="center"
      >
        <ReceptionLocation></ReceptionLocation>
      </Grid>
    </Grid>
  );
}
